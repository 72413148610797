<template>
  <div class="container">
    <div class="row" >
        <div class="title" >您还不是 {{title}} 会员？</div>
<div class="subtitle-box">
  <div class="subtitle">
   <span> 🛒 <b>购买充值码后，按下面步骤操作充值会员！</b></span>
  </div>
  <div class="subtitle warning-text">
    <span>📧 邮箱必须是自己的，充值需收验证码！</span>
  </div>
  <div class="subtitle info-text">
    <span>🌐 直接使用 <b>流量 | WIFI</b>，切勿随意挂梯子</span>

  </div>
  <div class="subtitle info-text">

    <span>🚨 <b>注意：IP 切换频繁会导致官方封号！</b></span>
  </div>
</div>


    </div>
    <el-divider></el-divider>
  <div class="form_title_box">
    <div class="form_title">账户升级</div>
    <div class="form_title_icon" @click="resetForm('ruleForm')"><i class="el-icon-refresh-right
"></i></div>
  </div>

  <el-form  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="50px" class="demo-ruleForm">

    <el-form-item label-width="50px" class="form-item" prop="user_email">
      <span class="label-icon" slot="label">
        <i class="el-icon-message form-icon" :style="emailicon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('email')" class="form-label" :class="emailactive" :style="emaillabel">* {{title}} 账户邮箱 <el-tooltip class="item" effect="light" content="如果邮箱未注册，系统会自动给您注册，并充值会员！" placement="top">
            <i class="el-icon-question" />
        </el-tooltip></label>

        <el-input ref="email" @blur="labelToBot('email')" @focus="labelToTop('email')" v-model="ruleForm.user_email" ></el-input>
        <!-- 只显示一个错误消息 -->
        </div>


    </el-form-item>


    <el-form-item label-width="50px" class="form-item" prop="user_passwd">
      <span class="label-icon" slot="label">
        <i class="el-icon-lock form-icon" :style="passwordicon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('password')" class="form-label" :class="passwordactive" :style="passwordlabel">* {{title}} 账户密码</label>
        <el-input ref="password" :show-password="true" @blur="labelToBot('password')" @focus="labelToTop('password')" v-model="ruleForm.user_passwd"></el-input>
      </div>
    </el-form-item>

    <el-form-item label-width="50px" class="form-item" prop="redom">
      <span class="label-icon" slot="label">
        <i class="el-icon-bank-card form-icon" :style="paycodeicon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('paycode')" class="form-label" :class="paycodeactive" :style="paycodelabel">* 充值代码</label>
        <el-input ref="paycode" minlength="10" @blur="labelToBot('paycode')" @focus="labelToTop('paycode')" v-model="ruleForm.redom" clearable>
        <el-button v-if="!ruleForm.redom" slot="append" style="background:#1976d2;color:#fff;"><a style="color:#fff;text-decoration:none;padding:10px 0" :href="'https://buy.shengpotian.shop'">立即购买</a></el-button>
        </el-input>

      </div>
    </el-form-item>

    <el-form-item label-width="50px" class="form-item" prop="contact">
      <span class="label-icon" slot="label">
        <i class="el-icon-postcard form-icon" :style="contacticon"></i>
      </span>
      <div class="form-input">
        <label @click="labelToTop('contact')" class="form-label" :class="contactactive" :style="contactlabel">联系邮箱（选填）</label>
        <el-input ref="contact" @blur="labelToBot('contact')" @focus="labelToTop('contact')" v-model="ruleForm.contact"></el-input>
      </div>
    </el-form-item>




      <el-button style="width:100%;padding:15px 20px" :type="submittype" @click="submitForm('ruleForm')" :disabled="submitstatus">立即升级</el-button>

  </el-form>

<el-dialog width="80%" top="35vh" :close-on-click-modal="false" :show-close="false" :close-on-press-escape="false" :modal="true" :visible.sync="dialogVisible">
  <i class="el-icon-loading dialog_icon"></i>
  <div class="dialog_text2">📧 小主不要走开呦，等会需提交验证码！</div><br>
  <div class="dialog_text1">正在升级中(3~5分钟)，请勿关闭页面！</div>
  <div class="dialog_text2">成功后需要重登账户以使会员生效！</div>
  <div class="dialog_text3">如过长时间无结果，请及时<a class="dialog_link" href="javascript:;">联系客服</a>查看！</div>
</el-dialog>

<el-dialog
  width="80%"
  top="35vh"
  :visible.sync="dialogVerifying"
  :close-on-click-modal="false"
  :close-on-press-escape="false"
  :modal="true"
  :show-close="false"
  class="custom-dialog"
>
  <div class="dialog-content">
    <i class="el-icon-loading dialog-icon"></i>

     <div class="dialog_text1">正在验证验证码(1~2分钟)，请勿关闭页面！</div>
    <div class="dialog_text2">验证成功后会继续进入会充值程序！</div>
    <div class="dialog_text3">如过长时间无结果，请及时<a class="dialog_link" href="javascript:;">联系客服</a>查看！</div>
  </div>
</el-dialog>






<el-dialog
  title="邮箱验证"
  :visible.sync="dialogCaptchaVisible"
  :width="dialogWidth"
>
  <div class="captcha-container">
    <!-- ✅ 在弹窗上方显示错误信息 -->
    <p v-if="captchaErrorMsg" class="captcha-error">{{ captchaErrorMsg }}</p>

    <p class="captcha-info">请输入收到的验证码：</p>
    <el-input
      v-model="captchaCode"
      placeholder="验证码"
      class="captcha-input"
      clearable
      @keyup.enter="submitCaptcha"
    ></el-input>

    <el-button type="primary" class="captcha-btn" @click="submitCaptcha">提交</el-button>
  </div>
</el-dialog>


<el-dialog
  title="温馨提示"
  :visible.sync="dialogEmailCheck"
  :width="dialogWidth"
  :close-on-click-modal="false"
  :close-on-press-escape="false"
  :modal="true"
  :append-to-body="true"
>
  <p class="dialog-text">
    充值需要邮箱验证码，您的邮箱可正常接收邮件？
  </p>
  <div class="dialog-buttons">
    <el-button type="danger" @click="cancelEmailCheck">不能</el-button>
    <el-button type="primary" @click="confirmEmailCheck">可以</el-button>
  </div>
</el-dialog>





  </div>



</template>

<script>
export default {

  data() {
    return {
      ruleForm: {
        user_email: "",
        user_passwd: "",
        redom: "",
        contact: "",
      },
      submittype: "info",
      submitstatus: true,
      captchaErrorMsg: "", // ✅ 存储验证码错误信息
      emailStatus: null, // ✅ 存储邮箱注册状态 (null: 未检查, true: 已注册, false: 未注册)
      checkingEmail: false, // ✅ 控制“正在检测”状态

      dialogEmailCheck: false, // ✅ 新增邮箱确认弹窗
      dialogCaptchaVisible: false, // 验证码弹窗
      dialogVerifying: false, // 验证码验证中弹窗
      dialogVisible: false, // 升级中弹窗
      captchaCode: "", // 存储验证码
      requestId: "", // 存储请求 ID

      rules: {
        user_email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { type: "email", message: "无效的邮箱格式", trigger: ["blur", "change"] },


        ],
        user_passwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 8, message: "密码至少需要8位", trigger: "blur" },
        ],
        redom: [
          { required: true, message: "请输入充值代码", trigger: "blur" },
          { pattern: /^[A-Za-z]{10,}$/, message: "无效的充值代码（至少10位英文字母）", trigger: "blur" },
        ],
        contact: [
          { type: "email", message: "无效的邮箱格式", trigger: ["blur", "change"] },
        ],
      },
    };
  },

  watch: {




  // **监听整个表单，控制提交按钮状态**
  ruleForm: {
    handler(nv) {
      if (nv.user_email && nv.user_passwd && nv.redom) {
        this.submittype = "primary";
        this.submitstatus = false;
      } else {
        this.submittype = "info";
        this.submitstatus = true;
      }
    },
    deep: true,
  },

  // **监听邮箱字段，每次变更自动检测邮箱状态**
  // **监听邮箱字段，每次变更时检测**
},


computed: {
  dialogWidth() {
    return window.innerWidth < 500 ? "80%" : "320px"; // ✅ 小屏 80%，大屏固定 320px
  }
},


  methods: {

    submitForm(formName) {
        this.clearCaptcha(); // ✅ **每次提交前清空验证码**
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogEmailCheck = true; // ✅ 先弹出邮箱确认弹窗
        } else {
          console.log("表单验证失败");
        }
      });
    },
    // ✅ **清空验证码**
  clearCaptcha() {
    this.captchaCode = "";
    this.captchaErrorMsg = "";
  },
    confirmEmailCheck() {
      this.dialogEmailCheck = false;
      this.getID();
    },

    cancelEmailCheck() {
      this.dialogEmailCheck = false;
      this.$notify({ title: "提醒", message: "请联系客服帮忙处理。", type: "warning" });
    },

    // 获取 ID
    getID() {
      this.dialogVisible = true; // **显示升级中弹窗**
      this.dialogCaptchaVisible = false; // **确保验证码窗口关闭**

      this.$http.post("/api/getID.json", this.ruleForm)
        .then((res) => {
          if (res.data.id) {
            this.requestId = res.data.id;
            this.getRes(this.requestId);
          } else {
            this.dialogVisible = false;
            this.$notify({ title: "错误", message: "系统错误，请联系客服！", type: "error" });
          }
        })
        .catch(() => {
          this.dialogVisible = false;
          this.$notify({ title: "网络错误", message: "请求失败，请检查网络连接", type: "error" });
        });
    },

    // 轮询获取结果
    getRes(id, captcha = null) {
      const payload = { id };
      if (captcha) {
        payload.captcha = captcha;
      }

      this.$http.post("/api/getResult.json", payload)
        .then((res) => {
          if (res.data.code === 2) {
            // **继续轮询**
            this.dialogVisible = true; // **显示升级中弹窗**
            setTimeout(() => {
              this.getRes(id, captcha);
            }, 5000);
          }
          else if (res.data.code === 7) {
            // **需要验证码，关闭升级窗口，显示验证码窗口**
            this.dialogVisible = false;
            this.dialogCaptchaVisible = true;
          }
          else if (res.data.code === 0) {
            // **成功，关闭升级窗口**
            this.dialogVisible = false;

            this.$notify({ title: "升级成功", message: res.data.msg, type: "success" ,showClose: true,duration:0});
          }
          else {
            // **其他错误，关闭窗口**
            this.dialogVisible = false;
            this.$notify({ title: "通知", message: res.data.msg, type: "error" ,showClose: true,duration:0});
          }
        })
        .catch(() => {
          this.dialogVisible = false;
          this.$notify({ title: "请求失败", message: "请检查网络状况", type: "error",showClose: true,duration:0 });
        });
    },


  openCaptchaDialog() {
    this.dialogCaptchaVisible = false; // ✅ 先关闭窗口，确保不会有残留问题

    this.$nextTick(() => {
      this.captchaCode = ""; // ✅ 清空验证码输入框
      this.$nextTick(() => {
        this.dialogCaptchaVisible = true; // ✅ 确保窗口在 UI 更新后再打开
      });
    });
  },



    // 提交验证码
    submitCaptcha() {
      if (!this.captchaCode) {
        this.$notify({ title: "提示", message: "请输入验证码", type: "warning" ,showClose: true,duration:0});
        return;
      }

      this.$http.post("/api/postcode.json", {
        id: this.requestId,
        captcha: this.captchaCode,
        email: this.ruleForm.user_email,
      })
      .then((res) => {
        if (res.data.code === 0) {
          this.pollPostcodeStatus(); // **开始轮询验证码是否正确**
        } else {
          this.$notify({ title: "验证码提交失败", message: "验证码异常，请重新输入", type: "error" ,showClose: true,duration:0});
        }
      });
    },

  // 轮询验证码状态（最多 1 分钟）
  pollPostcodeStatus(attempts = 0) {
    this.dialogCaptchaVisible = false; // **关闭验证码输入框**
    this.dialogVerifying = true; // **显示“正在验证”弹窗**

    this.$http.post("/api/postcode_status.json", {
      id: this.requestId,
      email: this.ruleForm.user_email,
    })
    .then((res) => {
      if (res.data.code === 1) {
      // ✅ **验证码正确，关闭验证弹窗，进入升级流程**
      this.dialogVerifying = false;
      this.$nextTick(() => {
        this.dialogVisible = true;
        this.getRes(this.requestId);
      });}
      else if (res.data.code === 3) {
        // ❌ **验证码不正确，需要重新输入**

        this.dialogVerifying = false;
        this.openCaptchaDialog(); // ✅ **调用方法清空并打开弹窗**
        //this.dialogCaptchaVisible = true; // **重新打开验证码输入框**
        this.captchaErrorMsg = "验证码不正确，请重新输入！"; // ✅ 显示在弹窗内
        //setTimeout(() => { this.captchaErrorMsg = ""; }, 5000); // ⏳ **错误提示 5s 后消失**
      }
      else if (res.data.code === 2) {
        // ❌ **系统异常，禁止用户重复尝试**
        this.dialogVerifying = false;
        this.captchaErrorMsg = "系统异常，请勿再次尝试，联系客服处理。"; // ✅ 显示在弹窗内
      }
      else {
        // 🔄 **继续轮询，每 2 秒查询一次，最多轮询 180s（90 次，每 2s）**
        if (attempts >= 100) {
          this.dialogVerifying = false;
          this.captchaErrorMsg = "验证码验证超时，请联系客服处理。"; // ✅ 显示在弹窗内
        } else {
          setTimeout(() => {
            this.pollPostcodeStatus(attempts + 1);
          }, 2000);
        }
      }
    })
    .catch(() => {
      this.dialogVerifying = false;
      this.captchaErrorMsg = "网络异常，请稍后重试。"; // ✅ 显示在弹窗内
    });
  },
      // **失焦时处理邮箱检测**
  handleEmailBlur() {
      this.labelToBot('email'); // ✅ 保持原有 UI 逻辑
      this.checkEmailStatus(); // ✅ 进行邮箱检测
    },

 // **判断邮箱格式是否正确**
  isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  },
  resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    labelToTop(tab){
      if(tab == 'paycode'){
        this.$refs.paycode.$refs.input.style.fontSize = '14px'
      }
      let activestr = tab+'active'
      let labelstr = tab+'label'
      let iconstr = tab+'icon'
      this[activestr] = {"label--active":true}
      this[labelstr] = "color:#1976d2"
      this[iconstr] = 'color:#1976d2'
      this.$refs[tab].focus()
      this.$refs[tab].$refs.input.style.border = '1px solid #1976d2'
    },
    labelToBot(tab){
      let value = ''
      switch(tab){
        case 'email':
          value = this.ruleForm.user_email
          break
        case 'password':
          value = this.ruleForm.user_passwd
          break
        case 'paycode':
          value = this.ruleForm.redom
          break
        default:
          value = this.ruleForm.contact
      }

      if(!value){
        let activestr = tab+'active'
        this[activestr] = {"label--active":false}
      }
      let labelstr = tab+'label'
      this[labelstr] = "color:#000"
      let iconstr = tab+'icon'
      this[iconstr] = 'color:#000'
      this.$refs[tab].$refs.input.style.border = '1px solid rgba(0, 0, 0, .7)'
    }





  },
};


</script>



<style scoped>

/* 调整弹窗整体 */
.captcha-container {
  text-align: center;
  padding: 15px; /* ✅ 让内容更紧凑 */
}

/* 错误提示 */
.captcha-error {
  color: red;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 8px; /* ✅ 适当间距 */
}

/* 提示文字 */
.captcha-info {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

/* 输入框 */
.captcha-input {
  width: 100%;
  font-size: 16px;
  text-align: center;
  padding: 6px;
}

/* 按钮 */
.captcha-btn {
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  padding: 8px;
}




.container{
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    max-width:800px;
}
.container >>> .el-dialog{
  max-width: 500px;
}
.container >>> .el-dialog__body{
  padding:0;
  text-align: center;
  padding-bottom: 30px;
}
.dialog_icon{
  font-size: 50px;
  padding-bottom: 8px;
}
.dialog_text1{
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 8px;
}
.dialog_text2{
  color:red;
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 8px;
}
.dialog_text3{
  color:rgba(0, 0, 0, .5)
}
.row {
    text-align: center;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.title{
    font-size: 19px!important;
    font-weight: 500;
    letter-spacing: .0125em!important;
}
.subtitle{
    font-size: 16px!important;
    letter-spacing: .009375em!important;
    line-height: 1.75rem;
    font-weight: 400;
    margin-top: 4px;
}
.el-divider--horizontal{
  margin:8px 0 !important;
}
.form_title_box{
  display: flex;
  height: 40px;
  line-height: 40px;
  margin-top: 14px;
  justify-content: flex-start;
}
.form_title{
  font-weight: bold;
  font-size: 24px;
}
.form_title_icon{
  margin-left: auto;
}
.form_title_icon{
  font-size: 22px;
  color: #fb8c00 ;
  font-weight: bold;
}
.form-icon{
  font-size: 26px;
}
.form-input >>> .el-input__inner{
  border-top:none !important;
  border-left:none !important;
  border-right:none !important;
  border-bottom: 1px solid rgba(0, 0, 0, .7);
  border-radius: 0 !important;
  padding:0 5px;
  font-size: 18px;
}
.label--active{
  transform: translateY(-25px) scale(.75);
  margin-left: -10px;
  color:#1976d2
}
.form-input{
  position: relative;
}
.form-label{
  position: absolute;
  top: 0;
  z-index: 999;
  font-size: 18px;
}
.form-item{
  margin-top:30px;
}
.demo-ruleForm{
  margin-top:20px;
}
.demo-ruleForm >>> .el-form-item__label{
  line-height: 50px !important;
}
.demo-ruleForm >>> .el-form-item__error{
  padding-top: 0 !important;
  top:90%;
}

.email-status-container {
  display: flex;
  flex-direction: row;  /* ✅ 让所有提示信息排成一行 */
  align-items: center;  /* ✅ 文字垂直居中 */
  gap: 8px;  /* ✅ 文字之间的间距 */
  font-size: 12px;
  margin-top: 4px; /* ✅ 让提示与输入框更紧凑 */
}

.checking-email {
  color: blue;
}

.error-email {
  color: red;
}

.success-email {
  color: green;
}




/* 让文本内容更加紧凑 */
.dialog-text {
  text-align: center;
  font-size: 14px; /* ✅ 手机端字体稍微小一点 */
  color: #555;
  margin: 10px 15px; /* ✅ 适配不同屏幕 */
}

/* 让按钮更紧凑，不会占用太多空间 */
.dialog-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* ✅ 按钮间隔 */
  margin-top: 15px;
}

.dialog-buttons .el-button {
  flex: 1; /* ✅ 让按钮自适应 */
  max-width: 120px; /* ✅ 防止按钮过宽 */
}

/* 让内容整体居中 & 适应不同屏幕 */
.subtitle-box {
  text-align: center;
  margin: 10px 0;
}

/* 通用的 subtitle 样式 */
.subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* 重点提示（SPT小铺）*/
.highlight-shop {
  color: #e91e63; /* 玫红色，吸引用户注意 */
  font-weight: bold;
}

/* 警告文本 */
.warning-text {
  color: red;
  font-weight: bold;
  font-size: 15px;
}

/* 提示信息 */
.info-text {
  color: #1976d2;
  font-size: 15px;
  font-weight: bold;
}


</style>